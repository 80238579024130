import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-partner-footer',
  templateUrl: './partner-footer.component.html',
  styleUrls: ['./partner-footer.component.scss']
})
export class PartnerFooterComponent implements OnInit {
  dateNow = new Date();
  isMobileView = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (window.innerWidth <= 700) {
      this.isMobileView = true;
    }
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
}
