<footer class="footer page-footer font-small mdb-color">
    <div class="container-fluid text-center text-md-left">
        <!-- <div *ngIf="!isMobileView" class="row d-flex align-items-center">            
            <div class="col-md-4">              
              <p class="text-center text-md-left">Designed By:
                <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
              </p>
            </div>
            <div class="col-md-4 text-center">
                <a (click)="navigateTo('/')">
                    <img height="50px" src="assets/images/Jaayka-Header.jpeg" />
                </a>
            </div>
            <div class="col-md-4">              
              <p class="text-center">© Copyright: {{dateNow | date: 'yyyy'}} Jaayka</p>
            </div>      
        </div> -->
        <div class="align-items-center text-center">
          <a (click)="navigateTo('/')">
            <img height="50px" src="assets/images/Jaayka-Header.jpeg" />
          </a>
          <p class="text-center">© Copyright: {{dateNow | date: 'yyyy'}} Jaayka</p>
          <p class="text-center">Designed By:
            <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
          </p>
        </div>
    </div>
</footer>
