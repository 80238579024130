<nav class="navbar fixed-top">
  <div class="navbar-logo">
    <a (click)="navigateTo('/restaurants')">
      <img class="header-logo" src="assets/images/Jaayka-Header.jpeg" />
    </a>
  </div>
  <div class="navbar-actions row">
    <a *ngIf="!isUserExist" class="nav-link" [class.nav-active]="currentUrl === '/login'" (click)="navigateTo('/login')"><i class="fa fa-sign-in"></i></a>
    <h4 *ngIf="isUserExist">{{user.fullName}}</h4>
    <a *ngIf="isUserExist" class="nav-link" (click)="logout()"><i class="fa fa-sign-out"></i></a>
  </div>
</nav>