import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  restIDSubject = new Subject();

  constructor(public http: HttpClient) { }  

  sendRestID(restID){
    //console.log(product)
    this.restIDSubject.next(restID);
  }

  getRestID(){
    return this.restIDSubject.asObservable();
  }

  gets3BucketURL(bucketName: string) {
    return `${environment.s3BaseURL}${bucketName}/`;
  }

  getRestaurants() {
    return this.http.get(`${environment.apiBaseURL}restaurants`, this.httpOptions);    
  }

  getRestaurant(restID: string) {
    return this.http.get(`${environment.apiBaseURL}restaurant?restID=${restID}`, this.httpOptions);
  }

  getOpeningHours(restID: string) {
    return this.http.get(`${environment.apiBaseURL}openingHours?restID=${restID}`, this.httpOptions);
  }

  getOffers(restID: string) {
    return this.http.get(`${environment.apiBaseURL}offers?restID=${restID}`, this.httpOptions);
  }

  getDeliveryCharges(restID: string) {
    return this.http.get(`${environment.apiBaseURL}deliveryCharges?restID=${restID}`, this.httpOptions); 
  }

  getCategories(restID: string) {
    return this.http.get(`${environment.apiBaseURL}categories?restID=${restID}`, this.httpOptions);
  }

  getMenus(restID: string) {
    return this.http.get(`${environment.apiBaseURL}menus?restID=${restID}`, this.httpOptions);
  }

  getEvents(restID: string) {
    return this.http.get(`${environment.bookingBaseURL}events?restID=${restID}`, this.httpOptions);
  }

  contactUs(obj: any) {
    return this.http.post<any>(`${environment.apiBaseURL}contact`, obj, this.httpOptions);
  }

  deleteAccount(obj: any) {
    return this.http.post<any>(`${environment.apiBaseURL}delete-account`, obj, this.httpOptions);
  }

  suggestARestaurant(obj: any) {
    return this.http.post<any>(`${environment.apiBaseURL}suggest`, obj, this.httpOptions);
  }
}
