<!-- Footer -->
<footer class="footer page-footer font-small mdb-color">
  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">
    <!-- Footer links -->
    <div class="row text-center text-md-left mt-3">
      <!-- Grid column -->
      <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3" style="text-align: center;">
        <a class="navbar-brand" routerLink="/"><img class="foot-img" height="100" width="100" src="assets/images/Jaayka-Logo.jpeg"></a>
      </div>      
      <hr class="w-100 clearfix d-md-none">
      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
        <h6 class="text-uppercase title-f mb-4 ">About Us</h6>
        <p class="para-foot">We bridge between restaurants and customers to extend reach to delicious food with taste you love. </p>
      </div>      
      <hr class="w-100 clearfix d-md-none">    
      <!-- Grid column -->
      <div class="col-md-1 col-lg-1 col-xl-1 mx-auto mt-3"></div>        
      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
        <h6 class="text-uppercase mb-4 title-f">Contact</h6>
        <p>
          <i class="fa fa-home mr-3"></i>
          <a>St Albans, Hertfordshire, United Kingdom<br></a>
        </p>
        <p>
          <i class="fa fa-envelope mr-3"></i> 
          <a href="mailto:jaayka@jmvl.co.uk">jaayka&#64;jmvl.co.uk</a>
        </p>
        <p>
          <i class="fa fa-phone mr-3"></i>07501873305
        </p>
        <div class="social">
          <a target="_blank" href="https://www.facebook.com/jaaykaofficial"><i class="fa fa-facebook mr-3"></i></a>
          <a target="_blank" href="https://www.instagram.com/jaayka.app/"><i class="fa fa-instagram mr-3"></i></a>
        </div>        
      </div>           
    </div>
    <!-- Footer links -->
    <hr>
    <!-- Grid row -->
    <div class="row d-flex align-items-center">
      <!-- Grid column -->
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center text-md-left">Designed By:
          <a class="foot-anchor" target="_blank" href="https://www.jmvl.co.uk">Jai Maa Vaibhav Laxmi Ltd</a>
        </p>
      </div>
      <div class="col-md-4">
        <!--Copyright-->
        <p class="text-center">© Copyright: {{dateNow | date: 'yyyy'}} Jaayka</p>
      </div>      
    </div>
    <!-- Grid row -->
  </div>
  <!-- Footer Links -->
</footer>
<!-- Footer -->