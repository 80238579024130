export const environment = {
  production: false,

  //************************ Jaayka Base URLs ********************************/
  s3BaseURL: "https://3ow3417fgl.execute-api.eu-west-2.amazonaws.com/prod/",
  apiBaseURL: 'https://52zl0s96aj.execute-api.eu-west-2.amazonaws.com/prod/v1/',
  authBaseURL: 'https://cmjblpjtz6.execute-api.eu-west-2.amazonaws.com/prod/v1/',
  orderBaseURL: 'https://ixdumhs5s7.execute-api.eu-west-2.amazonaws.com/prod/v1/',
  bookingBaseURL: "https://u0q3spodaa.execute-api.eu-west-2.amazonaws.com/prod/v1/",
  paymentBaseURL: "https://c92t3qg6xk.execute-api.eu-west-2.amazonaws.com/prod/v1/",
  adminBaseURL: 'https://qq7hsukmtb.execute-api.eu-west-2.amazonaws.com/prod/v1/',
  //****************************** Order Online APIs ******************************/
  getVarAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getVar',
  getMenuAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getMenu',  
  updateVarAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/updateVar',
  getCategoryAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getCategories',
  getDiscountsAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getDiscounts',  
  getOpeningHoursAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getOpeningHours',    
  getDeliveryChargesAPIUrl: 'https://hd2rfat5dg.execute-api.eu-west-2.amazonaws.com/prod/getDeliveryCharges',

  //****************************** Manage Order APIs ******************************/
  saveOrderAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/saveOrder',
  getAllOrdersAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/getOrders',
  updateOrderAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/updateOrder',
  feedbackReqAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/feedbackRequest',
  bookATableReqAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/bookATableRequest',
  changeOrderStatusAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/changeOrderStatus', 
  getOrderByDateRangeAPIUrl: 'https://tl7qpjekw3.execute-api.eu-west-2.amazonaws.com/prod/getOrdersByDateRange', 
  getOrdersByDatesAPIUrl: 'https://3ho73kcky1.execute-api.eu-west-2.amazonaws.com/test/getordersbydates',

  //****************************** CMS APIs ******************************/
  addOfferAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/addOffer',
  addNoticeAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/addNotice',
  getNoticesAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/getNotices',
  addHolidaysAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/addHoliday',    
  deleteOfferAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/deleteOffer',
  deleteNoticeAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/deleteNotice',
  deleteHolidaysAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/deleteHoliday',
  getOpeningStatusAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/getOpeningStatus',
  updateOpeningHoursAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/updateOpeningHours',
  addDeliveryChargesAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/addDeliveryCharges',
  deleteDeliveryChargesAPIUrl: 'https://tsgjrfm132.execute-api.eu-west-2.amazonaws.com/prod/deleteDeliveryCharges',

  //****************************** Stripe APIs ******************************/
  createStripeSessionAPIUrl: 'https://hkznglurvf.execute-api.eu-west-2.amazonaws.com/prod/cerateSession',
  retrieveStripeSessionAPIUrl: 'https://hkznglurvf.execute-api.eu-west-2.amazonaws.com/prod/retrieveSession',

  // ========================================================================================================================
  
  getItemPurchasedAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/getitempurchased',
  getGlobalVariablesAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/getglobalvariables',
  setOffersAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/setoffers',
  updateGlobalVariableAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/updateglobalvariable',

  emailSenderAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/emailsender',
  sendEmailToUserAPIUrl: 'https://y6pzvdmj70.execute-api.eu-west-2.amazonaws.com/prod/emailtouser',

  //****************************** Jhakhas Menu ************************************************/
  getJhakhasMenu: 'https://ioju2wc7hc.execute-api.eu-west-2.amazonaws.com/prod/getmenu'
};