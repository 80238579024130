import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-partner-header',
  templateUrl: './partner-header.component.html',
  styleUrls: ['./partner-header.component.scss']
})
export class PartnerHeaderComponent implements OnInit {

  user: any;
  currentUrl = '';
  isUserExist = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { 
    this.routeEvent(this.router);    
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        //console.log(e.url);
        this.currentUrl = e.url;
      }
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("User"));
    this.isUserExist = this.user !== null;
    console.log("User Exist: ", this.isUserExist);       
  }

  navigateTo(url: string) {
    // this.restaurant = JSON.parse(localStorage.getItem('Restaurant'));
    // if (url === '/book-a-table' && !this.restaurant.bookATable) {
    //   Swal.fire({  
    //     title: 'Book A Table is not available for ' + this.restaurant.restaurantName,       
    //     icon: 'error',   
    //     confirmButtonText: 'OK'         
    //   });
    // } else if (url === '/order' || url === '/book-a-table') {
    //   url = '/restaurants/' + this.restaurant.restID + url;
    //   this.currentUrl = url;
    //   this.router.navigate([url]);      
    // } else {
    //   console.log("Header URL: ", url);
    //   if (url === "/login") {
    //     localStorage.setItem("isLogin", JSON.stringify(true));
    //   }            
    // }
    // $('#nav-mbl-head').click();


    this.currentUrl = url;
    this.router.navigate([url]);
  }

  logout() {
    Swal.fire({
      title: 'Are you sure want to Logout?',      
      icon: 'warning',            
      showDenyButton: true,
      denyButtonText: "No, Keep me LoggedIn",      
      confirmButtonText: "Yes, Logout!",
      confirmButtonColor: "#555",
      customClass: {          
        confirmButton: "alert-button",
        denyButton: "alert-button" 
      }          
    }).then(result => {          
      if (result.isConfirmed) {
        this.authService.logout();
        location.reload();
      }
    });      
  }
}
