import { OrderService } from './services/order.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxDatesPickerModule } from 'ngx-dates-picker';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedComponentModule } from './shared-component/shared-component.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

@NgModule({
  declarations: [
    AppComponent,
    ScrollTopComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    NgxDatesPickerModule,
    SharedComponentModule,
    BrowserAnimationsModule,    
    HttpClientModule,
    SlickCarouselModule, //or NgToggleModule
    ToastrModule.forRoot({ timeOut: 2000 }) // ToastrModule added
  ],
  providers: [
    DatePipe,
    OrderService
   //{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
