<nav class="navbar navbar-expand-md navbar-light fixed-top for-mobile">
  <a class="navbar-brand"  (click)="navigateTo('/')"><img height="50" width="112.5" src="assets/images/Jaayka-Header.jpeg"></a>
  <button type="button" id="nav-mbl-head" style="float: right !important;margin-top: 15px;"
    class="navbar-toggler bg-light" data-toggle="collapse" data-target="#nav">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-between" id="nav">
    <ul class="navbar-nav text-center">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/about-us'" (click)="navigateTo('/about-us')">
          &nbsp;About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/order'" (click)="navigateToUrl('/order')"> &nbsp;Order Online</a>
      </li>       
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/book-a-table'"
          (click)="navigateToUrl('/book-a-table')">Book A Table</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateTo('/contact-us')">
          &nbsp;Contact Us&nbsp;</a>
      </li>        
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/feedback'"
          (click)="navigateTo('/feedback')">Feedback</a>
      </li> 
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/restaurants'"
          (click)="navigateTo('/restaurants')">Restaurants</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'"
          (click)="navigateTo('/contact-us')">Suggest A Restaurant</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/delete-account'"
          (click)="navigateTo('/delete-account')">Delete Account</a>
      </li>  
      <li class="nav-item" *ngIf="!isUserExist">
        <a class="nav-link" [class.nav-active]="currentUrl === '/login'"
                    (click)="navigateTo('/login')">  &nbsp;Login&nbsp;</a>
      </li>
      <li class="nav-item" *ngIf="isUserExist">
        <a class="nav-link" (click)="logout()">&nbsp;Logout&nbsp;</a>
      </li>
    </ul>
  </div>
</nav>

<nav class="navbar navbar-expand-lg  fixed-top show-for-web">
  <div class="collapse navbar-collapse justify-content-between" style="padding-left: 30px;padding-right: 30px;" id="navbarToggle">
    
    <a *ngIf="showHideMenu" class="navbar-brand d-none d-lg-block" (click)="navigateTo('/')">
      <img class="header-logo" src="assets/images/Jaayka-Header.jpeg"/>
    </a>
    
    <ul class="navbar-nav" *ngIf="showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/restaurants'"(click)="navigateTo('/restaurants')">Restaurants</a>
      </li>      
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/about-us'" (click)="navigateTo('/about-us')">|&nbsp;About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateTo('/contact-us')">|&nbsp;Contact Us&nbsp;</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/feedback'" (click)="navigateTo('/feedback')">|&nbsp;Feedback&nbsp;</a>
      </li>   
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'" (click)="navigateTo('/contact-us')">|&nbsp;Suggest A Restaurant</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/delete-account'" (click)="navigateTo('/delete-account')">|&nbsp;Delete Account</a>
      </li>  
      <li class="nav-item">
        <a class="nav-link" (click)="toggleMenu()"><i class="fa fa-bars" aria-hidden="true"></i></a>
      </li>    
    </ul>

    <ul class="navbar-nav min-menu" *ngIf="!showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/restaurants'"
          (click)="navigateTo('/restaurants')">Restaurants</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/contact-us'"
          (click)="navigateTo('/contact-us')">Suggest A Restaurant</a>
      </li>
    </ul>
    <!--   Show this only lg screens and up   -->
    <a *ngIf="!showHideMenu" class="navbar-brand d-none d-lg-block" (click)="navigateTo('/')">
      <img class="header-logo" src="assets/images/Jaayka-Header.jpeg"/>
    </a>

    <ul class="navbar-nav" *ngIf="!showHideMenu">
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/book-a-table'"
          (click)="navigateToUrl('/book-a-table')">Book A Table</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.nav-active]="currentUrl === '/order'" (click)="navigateToUrl('/order')"> |
          &nbsp;Order Online</a>
      </li>  
      <li class="nav-item" *ngIf="!isUserExist">
        <a class="nav-link" [class.nav-active]="currentUrl === '/login'"
                    (click)="navigateTo('/login')">|&nbsp;Login&nbsp;</a>
      </li>
      <li class="nav-item" *ngIf="isUserExist">
        <a class="nav-link" (click)="logout()">|&nbsp;Logout&nbsp;</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="toggleMenu()"><i class="fa fa-bars" aria-hidden="true"></i></a>
      </li>     
    </ul>
  </div> 
</nav>

<div class="container-fluid marque">
  <div class="row">
    <div class="col-md-12">
      <marquee class="marq" id="mark" style="margin:5px; color: black; font-size: large; font-weight: bolder;" direction="left" loop="">
        {{ message }}
      </marquee>
    </div>
  </div>
</div>