import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from '../../../services/order.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  dateNow = new Date();
  days: any;

  constructor(
    private router: Router,
    private orderService: OrderService,
  ) { }

  ngOnInit(): void { 
    
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]);
  }
}
