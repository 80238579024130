import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent, FooterComponent, PartnerHeaderComponent } from './component';
import { PartnerFooterComponent } from './component/partner-footer/partner-footer.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PartnerHeaderComponent,
    PartnerFooterComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PartnerHeaderComponent,
    PartnerFooterComponent
  ]
})
export class SharedComponentModule { }