import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, catchError, shareReplay, delay, mergeMap, retryWhen } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const getErrorMessage = (maxRetry: number) =>
  `Tried to load resource for ${maxRetry} times without success. Please refresh page manually.`
const DEFAULT_MAX_RETRIES = 3;

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(delayMs),
        mergeMap(error =>
          retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry))
        ))
      )
    );
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  login(loginRequest: any) {
    return this.http.post<any>(`${environment.authBaseURL}login`, loginRequest, { headers: this.httpHeaders }).pipe(map(response => {
      return response;
    }));
  }

  logout() { 
    localStorage.clear();       
    localStorage.removeItem('currentUser');
    localStorage.removeItem('User');
    localStorage.removeItem('Token');    
    this.currentUserSubject.next(null); 
}

  register(registerRequest: any) {
    return this.http.post<any>(`${environment.authBaseURL}register`, registerRequest, { headers: this.httpHeaders });
  }

  validateReferralCode(referralCode: string) {
    return this.http.post<any>(`${environment.authBaseURL}validateReferral`, JSON.stringify(referralCode), { headers: this.httpHeaders });
  }

  checkTokenExpiry(token: string) {
    var authHttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(`${environment.authBaseURL}checkTokenExpiry`, { headers: authHttpHeaders });
  }

  getCurrentUser(token: string, platform: string): Observable<string> {
    var authHttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(`${environment.authBaseURL}currentUser`, { headers: authHttpHeaders })
      .pipe(
        delayedRetry(1000, 3),
        catchError(error => {
          //console.error(error);
          return EMPTY;
        }),
        shareReplay()
      );
  }

  updateRewards(token: string, updateRewardRequest: any) {
    let authHttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',      
      'Authorization': `Bearer ${token}`
    });
    return this.http.post<any>(`${environment.authBaseURL}updateRewards`, updateRewardRequest, { headers: authHttpHeaders });
  }

  generateCode(generateCodeRequest: any) {
    return this.http.post<any>(`${environment.authBaseURL}generateCode`, generateCodeRequest, { headers: this.httpHeaders });
  }

  verifyEmail(verifyEmailRequest) {
    return this.http.post<any>(`${environment.authBaseURL}verifyEmail`, verifyEmailRequest, { headers: this.httpHeaders });
  }
}
