import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-partner-header',
  templateUrl: './partner-header.component.html',
  styleUrls: ['./partner-header.component.scss']
})
export class PartnerHeaderComponent implements OnInit {

  user: any;
  currentUrl = '';

  url: string;
  restID: string;
  restaurant: any;
  s3BaseURL = `${environment.s3BaseURL}restaurants-logo/`;

  constructor(
    private router: Router,
    private apiService: ApiService
  ) { 
    this.routeEvent(router);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        var urlArray = e.url.split('/');    
        //console.log("Partner Header URL Arr: ", urlArray);    
        let tempArray = [];
        if (urlArray.length > 2 && urlArray[2].includes('?')) {
          tempArray = urlArray[2].split('?');
          //console.log(tempArray);
          this.restID = tempArray[0];
        } else {
          this.restID = urlArray[2];
        }        
        //console.log("RestID: ", this.restID);
        if (tempArray.length >= 2) {
          this.url = '/restaurants/' + this.restID;
          this.router.navigate([this.url]);
        }
      }
    });
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("User"));    
    var existingRestaurant = JSON.parse(localStorage.getItem('Restaurant'));
    if (!(existingRestaurant != null && this.restID === existingRestaurant.restID)) {
      const token = localStorage.getItem("Token");
      const user = JSON.parse(localStorage.getItem("User"));
      localStorage.clear();
      if (token !== "null" || token !== null) {
        localStorage.setItem("Token", token);
        localStorage.setItem("User", JSON.stringify(user));
      }
      this.getRestaurant();
    } else {
      this.restaurant = existingRestaurant;
      localStorage.setItem('Restaurant', JSON.stringify(this.restaurant));
    }      
  }

  getRestaurant() {
    this.apiService.getRestaurant(this.restID).subscribe((res: any) => {
      //console.log('Restaurant Response: ', res);
      if (res.responseCode === 1) {
        this.restaurant = res.restaurant;  
        localStorage.setItem('Restaurant', JSON.stringify(this.restaurant));      
      }
    });
  }

  navigateToRestHome() {
    this.url = '/restaurants/' + this.restID;
    this.router.navigate([this.url]);
  }
}
