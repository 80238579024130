import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userName = '';
  currentUrl = '';
  showHideMenu = false;
  isUserExist = false;
  isRestaurant = false;
  restaurant: any;
  notices = [];
  message = '*** Onboard/Register with us to grab early bird offers either you are restaurant or a customer craving for delicious food. ***';

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.routeEvent(this.router);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        //console.log(e.url);
        //this.currentUrl = e.url;
        var urlArray = e.url.split('/'); 
        //console.log("URL Arr: ", urlArray);
        this.currentUrl = urlArray[1]; 
      }
    });
  }

  ngOnInit(): void {    
    if (localStorage.getItem('Restaurant') !== null) {
      this.isRestaurant = true;
      this.restaurant = JSON.parse(localStorage.getItem('Restaurant'));
      //console.log('Restaurant: ', this.restaurant);
    }
    let user = JSON.parse(localStorage.getItem("User"));
    this.isUserExist = user !== null;
    //console.log("User Exist: ", this.isUserExist);
  }

  // showMessage() {
  //   this.orderService.getAllNotices().subscribe((data: any) => {
  //     this.notices = JSON.parse(data.body);
  //     this.notices.forEach(notice => {
  //       if (notice.isActive === true) {
  //         this.message += notice.message;
  //         this.message += ' || ';
  //       }
  //       else {
  //         return;
  //       }
  //     })
  //   });
  // }

  navigateToUrl(url: string) {
    if (this.isRestaurant) {
      if (url === '/order' || url === '/book-a-table') {
        url = '/restaurants/' + this.restaurant.restID + url;
        this.currentUrl = url;
      }
      this.router.navigate([url]);            
    } else {      
      Swal.fire({  
        title: 'Please Select Restaurant',       
        icon: 'warning',  
        showCancelButton: true,  
        confirmButtonText: 'Restaurants',
        confirmButtonColor: "#555",
        customClass: {          
          confirmButton: "alert-button"    
        }      
      }).then((result) => {
        if(result.value) {
          this.router.navigate(['/restaurants']);
        }  
      });
    }  
    $('#nav-mbl-head').click(); 
  }

  navigateTo(url: string){
    //console.log("Header URL: ", url);
    if (url === "/login") {
      localStorage.setItem("isLogin", JSON.stringify(true));
    }
    this.router.navigate([url]);  
    $('#nav-mbl-head').click();  
  }

  toggleMenu() {
    this.showHideMenu = !this.showHideMenu;
  }

  logout() {
    Swal.fire({
      title: 'Are you sure want to Logout?',      
      icon: 'warning',            
      showDenyButton: true,         
      confirmButtonText: "Yes, Logout!",
      denyButtonText: "Dismiss",   
      confirmButtonColor: "#555",
      customClass: {          
        confirmButton: "alert-button",
        denyButton: "alert-button" 
      }          
    }).then(result => {          
      if (result.isConfirmed) {
        this.authService.logout();
        location.reload();
      }
    });      
  }
}