import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Jaayka';
  currentUrl = '';
  showPartner = false;
  home = false;
  isMobileView = false;

  constructor(router: Router) {
    //console.log('router', router.url);
    this.routeEvent(router);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        console.log("url: ", e.url);
        var urlArray = e.url.split('/'); 
        console.log("URL Arr: ", urlArray);
        this.currentUrl = urlArray[1];
        this.showPartner = this.currentUrl === "restaurants" && urlArray.length > 2 ? true : false;        
        this.home = urlArray[2] != "" && urlArray.length === 3 ? true : false;
        console.log("Home: ", this.home);
      }
    });
  }

  onActivate(event) {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  ngOnInit() {
    var agent = window.navigator.userAgent;
    console.log("Agent: ", agent);
    if (window.innerWidth <= 700) {
      this.isMobileView = true;  
      // Swal.fire({
      //   title: "For better user experience please open App in mobile.",          
      //   icon: 'info',            
      //   showPartnerDenyButton: true,
      //   denyButtonText: "Continue as Guest",
      //   denyButtonColor: "#FFBBB9",
      //   confirmButtonText: "Login/Register",
      //   confirmButtonColor: "#C4211C"          
      // }).then(result => {          
      //   if (result.isConfirmed) {
      //     localStorage.setItem("isLogin", JSON.stringify(true));
      //     this.redirectToLogin();
      //   } else if (result.isDenied) {
      //     localStorage.setItem("isLogin", JSON.stringify(false));
      //     this.redirectToLogin();
      //   }
      // });    
    }
  }
}
