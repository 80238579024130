import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
 
  msgSubject = new Subject()
  varSubject = new Subject()
  userSubject = new Subject()
  emailSubject = new Subject()
  categoryTypeSubject = new Subject()
 
  constructor(public http: HttpClient) { }
  
  sendMsg(product){
    //console.log(product)
    this.msgSubject.next(product);
  }

  getMsg(){
    return this.msgSubject.asObservable();
  }

  sendCategoryType(categoryType) {
    this.categoryTypeSubject.next(categoryType);
  }

  getCategoryType() {
    return this.categoryTypeSubject.asObservable();
  }

  sendVar(globalVar){
    this.varSubject.next(globalVar);
  }

  getVar(){
    return this.varSubject.asObservable();
  }

  sendUser(user: any){
    this.userSubject.next(user);
  }

  getUser(){
    return this.userSubject.asObservable();
  }

  sendEmail(email: any) {
    this.emailSubject.next(email);
  }

  getEmail() {
    return this.emailSubject.asObservable();
  }

  validatePostcode(postcode: any){
    var url = "https://api.postcodes.io/postcodes/" +postcode+"/validate";
    return this.http.get(url,this.httpOptions);
  }

  postcodeLookup(postcode: any){
    var url = "https://api.postcodes.io/postcodes/" +postcode;
    return this.http.get(url,this.httpOptions);
  }

  saveOrder(order: any){
    return this.http.post<any>(`${environment.orderBaseURL}order/save`, order, this.httpOptions);
  }  

  // Function to get Stripe Session
  createSession(obj: any) {
    return this.http.post(`${environment.paymentBaseURL}create-session`, obj, this.httpOptions);
  }

  retrieveSession(obj: any) {    
    return this.http.post(`${environment.paymentBaseURL}retrieve-session`, obj, this.httpOptions);
  }

  getOrders(restID: string) {    
    return this.http.get(`${environment.orderBaseURL}orders?restID=${restID}`, this.httpOptions);
  }

  updateOrderStatus(orderObj: any, token: string) {
    var authHttpHeaders = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${JSON.parse(token)}`
    });
    return this.http.post<any>(`${environment.orderBaseURL}order/update`, orderObj, { headers: authHttpHeaders});
  }

  bookATable(bookingObj: any) {
    return this.http.post<any>(`${environment.bookingBaseURL}table-booking/add`, bookingObj, this.httpOptions);
  }

  bookAnEvent(bookingObj: any) {
    return this.http.post<any>(`${environment.bookingBaseURL}event-booking/add`, bookingObj, this.httpOptions);
  }

  createSessionTickets(obj: any) {
    return this.http.post(`${environment.paymentBaseURL}event-tickets/create-session`, obj, this.httpOptions);
  }
}
